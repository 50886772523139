import React from 'react';
  
const Home = () => {
  return (
    <div class="container">
      
          </div>
  );
};
  
export default Home;