import React from 'react';

const Sobre = () => {
	return (
		<div class="container">
			<div class="geral animated fadeInUp">
				<div class="box-header">
					<h2>Sobre</h2>
				</div>
				<h3>e-SHIRT C@SUAL CLOT#ES e o mercado potencial</h3>
				<div>
					<a>Conforme dados de 2021 do IBGE, estima-se que o Brasil tenha uma população de 215 milhões de
						pessoas. A pirâmide etária da população, conforme censo de 2010, mostra que pessoas na faixa dos 15
						aos 60 anos, correspondiam a 2/3 da população, ou seja, 140 milhões de pessoas. Admitindo que não
						houve mudança significativa nessa estratificação, pois o censo de 2020/2021 foi cancelado,
						consideraremos este o mercado potencial que a empresa pode atingir.</a>

				</div>

				<br />

				<div>
					<a>Fatores adversos que foram alavancados pela pandemia de COVID-19, aceleraram mudanças
						comportamentais, e consequentemente, nas decisões de consumo no setor de moda e vestuário,
						gerando um público mais informado e exigente sobre as origens do produto, bem como mais conectado
						a roupas confortáveis e informais para uso nas atividades de lazer ou trabalho remoto (home-office).</a>
				</div>

				<br />

				<div>
					<a>Em paralelo, obedecendo à uma tendência global, as empresas que têm regime de trabalho presencial,
						estão em forte sintonia com assuntos ligados à inclusão e diversidade, o que implica de certa forma, na
						revisão dos padrões de vestuário dos seus colaboradores, dando-os mais liberdade de escolha para se
						sentirem melhor no trabalho, e consequentemente mais produtivos.</a>
				</div>



			</div>

		</div>
	);
};


export default Sobre;