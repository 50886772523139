import React from 'react';

const Projeto = () => {
  return (
    <div class="container">
      <div class="geral animated fadeInUp">
        <div class="box-header">
          <h2>Projeto</h2>
        </div>
        <h3>Página do Projeto: <a href="https://e-shirt-casual-clothes.store/">Acesse </a></h3>
        <h3>Plano de Negócio e-SHIRT C@SUAL CLOT#ES <a href="https://www.e-shirt-casual-clothes.store/files/PlanodeNegcio-e-SHIRTCSUALCLOTES.pdf">Download PDF</a></h3>
        <h3>Modelo Relacional do Banco de Dados <a href="https://www.e-shirt-casual-clothes.store/files/e-shirt-casual-clothesModeloRelacional.pdf">Download PDF</a></h3>
        <h3>API (Interface de programação): <a href="https://api.e-shirt-casual-clothes.store/">Acesse</a></h3>
        <h3>GitHub: <a href="https://github.com/e-shirt-casual-clothes/">Acesse </a></h3>
        <h3>Back-end: <a href="https://github.com/e-shirt-casual-clothes/e-shirt-backend">Código-fonte </a></h3>
        <h3>Front-end:<a href="https://github.com/e-shirt-casual-clothes/e-SHIRT-Frontend">Código-fonte </a></h3>
      </div>


    </div>
  );
};


export default Projeto;
