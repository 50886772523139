import React from 'react';

import SignRoutes from './SignRoutes';
import OtherRoutes from './OtherRoutes';

const Routes = () => {
   /*  const login = false; */
 return (
  <OtherRoutes />
 );
};

export default Routes;